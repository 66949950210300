@import 'semantic-ui-css/semantic.min.css';
@import "toastr";
@import 'bootstrap-daterangepicker/daterangepicker.css';

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  pointer-events: none;
  opacity: 1;
}

.ui.list .list>a.item, .ui.list>a.item {
  cursor: pointer;
  color: #000000;
  padding-bottom: 15px;
}

.ui.list .list>a.item, .ui.list>a.item:hover {
  cursor: pointer;
  color: #000000;
}

canvas {
  display: block;
  height: 580px !important;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td, th {
  text-align: center !important;
  text-transform: capitalize;
}

.pagination {
  float: right; 
  margin: 30px 0;
}

.ant-layout-footer {
  padding: 7px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}

.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
  cursor: pointer;
}

.ant-modal-footer {
  padding: 10px 16px;
  text-align: center;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
} */

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: .25rem;
  margin-top: 1rem;
}

th, td {
  text-transform: capitalize;
}

.ui.table {
  width: 100%;
  background: #fff;
  margin: 1em 0;
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: none;
  border-radius: .28571429rem;
  text-align: center;
  /* color: rgba(0,0,0,.87); */
  /* border-collapse: separate; */
  /* border-spacing: 0; */
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  background: #fff; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

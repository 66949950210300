.main {
    height: 100vh;
}

.center-view {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-div {
    display: flex;
    justify-content: center;
    padding: 100px !important;
}

.inner-card-div {
    width: 50vh;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 2px 4px 4px 4px #e2eef7 !important;
}

.card-header {
    display: flex; 
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: transparent;
    border-bottom: 0px solid !important;
}

.form {
    width: 100vh;
    padding: 15px;
}

.forgot-link {
    display: flex; 
    justify-content: center; 
    float: right;
}

.login-button {
    float: right;
    margin-top: 15px !important;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    color: rgb(92, 90, 90);
    text-align: center;
    border-top: 1px solid #e4e5e7;
    padding-top: 10px;
}

.card-title {
    font-weight: 600; 
    font-size: 16px;
}

/* .main {
	background: linear-gradient(-45deg, #ff4000, #ff005d, #00b7ff, #00ffc3);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */
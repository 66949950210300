.main {
    height: 100vh;
}

.center-view {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-div {
    display: flex;
    justify-content: center;
    padding: 60px;
}

.inner-card-div {
    width: 50vh;
    padding: 30px;
    border: 1px #e4e5e7;
    box-shadow: 2px 4px 4px 4px #e4e5e7;
}

.card-header {
    display: flex; 
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: transparent;
}

.form {
    width: 100vh;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    color: rgb(92, 90, 90);
    text-align: center;
    border-top: 1px solid #e4e5e7;
    padding-top: 10px;
}

.login-link {
    display: flex; 
    justify-content: center; 
}

.forgot-password-text {
    font-weight: 600;
    font-size: 16px;
}
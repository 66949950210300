  .header a.active {
    background-color: dodgerblue;
    color: white;
  }

  .header-right {
    float: right;
  }
  .header a.logo {
    font-size: 25px;
    font-weight: bold;
  }

  .header {
    overflow: hidden;
    background-color: #e9ecef;
    padding: 5px 5px;
  }
  
  .header span {
    float: left;
    color: black;
    text-align: center;
    padding: 12px 12px 12px 0px;
    text-decoration: none;
    font-size: 14px; 
    line-height: 35px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .header-right .header-text {
    color: #153659;
    cursor: pointer;
  }

  .header-right .header-text:hover {
    color: #153659;
    /* text-decoration: underline; */
  }

  .header-right .header-icon {
    color: #153659;
  }

  @media screen and (max-width: 500px) {
    .header a {
      float: none;
      display: block;
      text-align: left;
    }
    
    .header-right {
      float: none;
    }
  }

  .header-right img {
    min-height: 40px;
    min-width: 45px;
  }
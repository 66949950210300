.column-search-view{
    padding: 8;
}

.column-search-input{
    width: 188;
    margin-bottom: 8; 
    display: block;
}

.column-search-button{
    width: 90;
}

.column-header-icon {
    color: #1890ff;
}

.breadcrumb-view {
    margin: 16px 0;
}

.clear-button-view {
    margin: 10px 0px 10px 0px;
    padding-bottom: 30px;
}

.clear-button-view .clear-button {
    float: right;
    margin: 0px 0px 0px 15px;
}

.table-list {
    overflow-x: scroll;
    white-space: nowrap;
    height: 600px;
    padding-bottom: 10px; 
}

.table-footer {
    padding: 20px; 
}

.table-pagination {
    float: right;
}
.profile-form {
    width: 33%;
}

.profile-image-label {
    display: block;
    margin: 0 0 .28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
}

.profile-image {
    border: 5px solid lightgrey;
    cursor: pointer;
    padding: 0px;
    height: 120px;
}

.submit-button {
    background: #2185d0;
    color: #ffffff;
}
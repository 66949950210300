.breadcrumb-view {
    margin: 16px 0;
}


.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    /* border: 1px solid #abcdef; */
    /* margin-top: 10px; */
    box-shadow: 2px 4px 4px 4px #e4e5e7;
    cursor: pointer;
}

.card:hover {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    /* border: 1px solid #abcdef; */
    /* margin-top: 10px; */
    box-shadow: 2px 4px 4px 4px #e4e5e7;
    cursor: pointer;
    /* transition: width 2s, height 4s; */
    transition: transform .4s; 
    transform: scale(1.05);
    /* height: 300px; */
}

/* div {
    width: 100px;
    height: 100px;
    background: red;
    transition: width 2s, height 4s;
  }
  
  div:hover {
    width: 300px;
    height: 300px;
  } */

.card-subtitle {
    font-weight: bolder;
    font-size: 20px;
}

.count-card-view:hover {
    box-shadow: 2px 4px 4px 4px #c6ccd4;
}

.layout-view {
    margin-top: 25px;
}

.statistics-card-view {
    border: 1px #e4e5e7; 
    box-shadow: 2px 4px 4px 4px #e4e5e7;
    cursor: pointer;
}

.statistics-card-view:hover {
    box-shadow: 2px 4px 4px 4px #c6ccd4;
}

.table-view {
    margin-top: 15px;
}

.card-body-content-left {
    float: left;
}

.card-body-content-right {
    float: right;
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #e9ecef;
    color: #153659;
    text-align: center;
    border: 0px;
 }

 p:last-child {
    margin-bottom: 10px;
}
/* NEEDED */

  .sidebar {
    margin: 0;
    padding: 0;
    width: 230px;
    background-color: #153659;
    position: fixed;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }
  
  .sidebar a {
    display: block;
    color: #fff;
    padding: 16px;
    text-decoration: none;
  }
   
  .sidebar a.active {
    background-color: #e9ecef;
    color: #000;
    transform: scale(1.05);
  }
  
  .sidebar a:hover:not(.active) {
    background-color: transparent;
    color: white;
  }
  
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  }

